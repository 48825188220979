/* .h2 {
    margin: 0 0 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    line-height: 1.21;
    color: initial;
}
.h2 {
    font-size: 2.441em;
} */
@media (max-width:475px) and (min-width:380px) {
    .single-work-process {
        position: relative;
        float: left;
        margin: 0 15px;
        width: calc(50% - 30px);
    }
}

@media (max-width:768px) and (min-width:475px) {
    .single-work-process {
        position: relative;
        float: left;
        margin: 0 15px;
        width: calc(46% - 30px);
    }
}


@media (max-width:380px) {
    .single-work-process {
        position: relative;
        float: left;
        margin: 0 15px;
        width: 80%;
    }
}

@media (min-width:768px){
.single-work-process {
    position: relative;
    float: left;
    margin: 0 15px;
    width: calc(25% - 30px);
 }
}
.work-process-icon-wrap {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
}
.section {
    display: block;
}
.ptb-100 {
    padding: 0px 0px 100px 0px;
}
/* .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */

.text-center {
    text-align: center!important;
}
.color-1 {
    color: #ff164e;
}
.color-1-bg {
    background: rgba(255, 22, 78, 0.15);
}

.work-process-icon-wrap i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.icon-md {
    font-size: 40px;
    line-height: 40px;
}
.white-bg {
    background: #ffffff;
}
.color-primary {
    color: #3264f5;
}
.process-step {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Work Sans', sans-serif;
}
.color-2 {
    color: #9123ff;
}
.color-2-bg {
    background: rgba(145, 35, 255, 0.15);
}

.color-3 {
    color: #3413f7;
}

.color-3-bg {
    background: rgba(52, 19, 247, 0.15);
}
.color-4, .ratting-color {
    color: #ff7c3f;
}

.color-4-bg {
    background: rgba(255, 124, 63, 0.15);
}

.work-process-divider {
    position: absolute;
    top: 40px;
    width: calc(100% - 130px);
    height: 1px;
    background-image: linear-gradient(
90deg, #73778C, #73778C 40%, transparent 40%, transparent 100%);
    background-size: 12px 1px;
}
.single-work-process .work-process-divider:after {
    content: '\e628';
    font-family: 'themify';
    position: absolute;
    right: -30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}}
@media (min-width: 768px) and (max-width: 991.98px){
.container, .container-md, .container-sm {
    max-width: 720px;
}}
@media (min-width: 576px) and (max-width: 767.98px){
 .container-sm {
    max-width: 540px;
}}
@media (min-width: 992px) and (max-width: 1199px) {
.container {
    max-width: 960px;
}}
@media (min-width: 768px) and (max-width: 991.98px) {
.container {
    max-width: 720px;
}}
@media (min-width: 576px) and (max-width: 767.98px){
.container {
    max-width: 540px;
}}

