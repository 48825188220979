.whatsapp-linear {
  background: linear-gradient(
    90deg,
    rgba(255, 119, 52, 1) 0%,
    rgba(239, 39, 39, 1) 38%,
    rgba(220, 215, 10, 1) 100%
  );
  color: white !important;
  padding: 10px 30px;
  border-radius: 27px;
  display: flex;
  place-items: center;
  column-gap: 10px;
  font-size: medium;
  font-weight: 500;
}

