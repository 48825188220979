.imgClass1 {
  width: 360px;
  border-radius: 25px;
}

.imgClass2 {
  width: 600px;
  border-radius: 25px;
}

.img-contr {
  left: 170px;
  bottom: -150px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 7px !important;
  height: 45px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 1em !important;
}

.chatNow {
  position: fixed;
  z-index: 1031;
  top: 92%;
  left: 78%;
  font-size: 25px;
  color: #ff551c;
  font-weight: 600;
}

@media (max-width: 576px) {
  .chatNow {
    top: 92%;
    left: 19%;
    font-size: 20px;
    color: #ff551c;
    font-weight: 600;
  }
  .submitBtn {
    font-size: 15px !important;
  }
  .ctaBtn {
    font-size: 17px !important;
  }
  .login-img {
    width: 320px !important;
  }
}

.login-img {
  width: 509px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin: 0px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin: 0px !important;
}

.reset-password {
  position: absolute;
  top: 5vh;
}

@media (min-width: 668px) {
  .reset-password {
    position: absolute;
    top: 15vh;
    left: 32vw;
    width: 37%;
  }
}
